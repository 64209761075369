<template>
  <div>
    <PageTitle
        :icon=icon
        :heading="$t('settings.lang_editPrinter')"
        show-previous-button
        :subheading="$t('settings.lang_editPrinter')"
        url-previous='/settings/printerSettings/printerManager'
    ></PageTitle>

    <div class="app-main__inner">
      <EditPrinter></EditPrinter>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle";
import EditPrinter from "../../../components/settings/printerSettings/printManager/EditPrinter";

export default {
  name: "PrintManagerEditPrinter",

  components: {
    EditPrinter,
    PageTitle
  },

  data() {
    return {
      icon: 'pe-7s-plane icon-gradient bg-tempting-azure'
    }
  }
}
</script>